<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
    components: {
    //
    },
}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

#app {
  font-family: 'Open Sans', sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden !important;
  height: 100%;
}
</style>

