import axios from 'axios'
import Vue from "vue";

const server_path = process.env.VUE_APP_HTTP_S + '://' + process.env.VUE_APP_API_HOST + '/managementData/';

export function postData(url, datos) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
    return axios
      .post(server_path + url, datos)
      .then(res => res)
      .catch(error => {
        if (error.response) {
          return error.response
        } else {
          return error
        }
    })
}

export function postDataAWS(url, bucket){
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, bucket)
    .then(res => res)
      .catch(error => {
        if (error.response) {
          return error.response
        } else {
          return error
        }
    })
}

export function postDataUpload(url, datos, onUploadProgressCallback){
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, datos, {
      onUploadProgress: onUploadProgressCallback
    })
    .then(res => res)
      .catch(error => {
        if (error.response) {
          return error.response
        } else {
          return error
        }
    })
}

export function postDataDownload(url, datos){
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, datos, {responseType: 'blob'})
    .then(res => res)
      .catch(error => {
        if (error.response) {
          return error.response
        } else {
          return error
        }
    })
}

export function postDataDelete(url, datos){
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, datos)
    .then(res => res)
      .catch(error => {
        if (error.response) {
          return error.response
        } else {
          return error
        }
    })
}