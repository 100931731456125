import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SilentCheckSSO from "../views/SilentCheckSSO.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
    component: Home,
    // meta: {
    //   requiresAuth: true,
    // }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
