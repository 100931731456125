<template>
  <div class="container-fluid">
    <div class="container-fluid container-degradado position-absolute top-0 start-0 align-items-start d-flex">
      <img class="scale-image mt-2 me-1" src="../assets/CTN20_white_transparent_anos.png" alt="logoCTN20Años">
      <p class="title col-2 mt-1 fw-blod fs-6 text-start ms-2">Data Manager</p>
      <div class="selector-container position-absolute top-0 start-50 mt-1 d-flex border border-dark border-opacity-10 rounded">
        <p id="seleccion" class="text-ubicacion text-start ms-3">Location: </p>
        <div class="col-8 ms-3 mt-1">
          <select v-model="selec_ubication" class="form-select form-select-style form-select-sm text-start" @change="getFolders(current_path, selec_ubication), getFoldersAWS(current_path_AWS)">
            <option value="Acoustic" :disabled="user_view != 'all_permission'">Acoustic</option>
            <option value="DS">DS</option>
          </select>
        </div>
      </div>
      <div class="position-absolute top-0 end-0 mt-1 me-5">
        <div class="dropdown">
          <button type="button" class="btn p-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="admin ? 'rgb(1, 87, 116)' : 'white'" class="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
          </button>
          <div class="dropdown-content">
            <a @click="logout" href="#">Logout</a>
            <a v-if="user_group == 'MiembrosCTN'" @click="loginAdmin"  href="#">Login admin</a>
          </div>
        </div>
        <!-- <button type="button" id="user-option" class="btn p-0" data-bs-toggle="dropdown" aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="admin ? 'rgb(1, 87, 116)' : 'white'" class="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
          </svg>
        </button>
        <ul class="dropdown-menu" aria-labelledby="user-option">
          <li><a class="dropdown-item" @click="logout" href="#">Logout</a></li>
          <li><a class="dropdown-item" @click="loginAdmin" href="#">Login admin</a></li>
        </ul> -->
      </div>
    </div>
    <div v-if="user_group != 'ClientesCTN'" class="container-fluid position-absolute top-50 start-50 translate-middle row justify-content-center mt-4" style="height: 90%">
      <div class="local-files-container col-12 cold-sm-12 col-md-12 col-lg-5 col-xl-5 border border-secondary-subtle rounded-4 border-opacity-10">
        <div class="col-12 text-start mt-2 d-flex position-relative" style="word-break: break-word;">
          <button type="button" class="btn btn-outline-secondary p-0" style="width: 25px; height: 27px;" @click="backList">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="Gray" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
          </button>
          <div class="ms-2" style="flex: 1;">
            {{ current_path }}
          </div>
          <img class="img-fluid" src="../assets/logo_ctn_new_2024_color.png" alt="logoCTNColor">
        </div>
        <div class="files-list border border-white overflow-scroll mt-2">
          <div v-if="files_in_path">
            <table>
              <tr v-for="(element, index_element) in files_in_path" v-bind:key="index_element">
                <td v-if="element.type == 'd'" class="files-icons folder-icon"></td>
                <td v-else class="files-icons file-icon"></td>
                <td v-if="element.type =='d'" v-bind:class="{ 'pointer-class': element.type == 'd' }" @click="navigate(element.name)">{{ element.name }}</td>
                <td v-else @click="selectFile(element.name, 'local')">{{ element.name }}</td>
                <td>{{ element.size }}</td>
                <td v-if="element.type != 'd' && user_view != 'read_permission'" style="min-width: 90px;">
                  <div class="d-grid gap-2 d-md-block justify-content-md-end">
                    <button class="button_icons btn me-md-2 icon-delete float-end" :disabled="user_view == 'read_permission'" type="button"  data-bs-toggle="modal" data-bs-target="#successModal" @click="deleteFileChange('local')"></button>
                    <button class="button_icons btn icon-download float-end" type="button" @click="downloadFileChange('local')"></button>
                    
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      
          <button type="button" class="btn btn-light text-primary m-2 border-dark border-opacity-25 rounded mt-2 float-end btn-sm" @click="openFileSelector" :disabled="loading">Upload files</button>
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
      </div>

      <div v-if="user_group != 'ClientesCTN'" class="local-files-container col-12 cold-sm-12 col-md-12 col-lg-5 col-xl-5 border border-secondary-subtle rounded-4 border-opacity-10" style="margin-left: 20px; background-color: rgb(35,48,61);">
        <div class="col-12 text-start mt-2 d-flex position-relative">
          <button type="button" class="btn btn-outline-secondary p-0" style="width: 25px; height: 27px;" @click="backListAWS">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="Gray" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
          </button>
          <div class="ms-2 text-light" style="flex: 1;">
            {{ current_path_AWS }}
          </div>
          <img class="img-fluid-AWS" src="../assets/LogoAWS@512x.png" alt="logoAWS">
        </div>
        <div class="files-list border border-white overflow-scroll mt-2">
          <div v-if="files_in_path_AWS">
            <table>
              <tr v-for="(element, index_element) in files_in_path_AWS" v-bind:key="index_element">
                <td v-if="element.type == 'd'" class="files-icons folder-icon"></td>
                <td v-else class="files-icons file-icon"></td>
                <td v-if="element.type =='d'" v-bind:class="{ 'pointer-class': element.type == 'd' }" @click="navigateAWS(element.name)">{{ element.name }}</td>
                <td v-else @click="selectFile(element.name, 'AWS')">{{ element.name }}</td>
                <td>{{ element.size }}</td>
                <td v-if="element.type != 'd' && admin ">
                  <div class="d-grid gap-2 d-md-block justify-content-md-end">
                    <button type="button" class="button_icons btn icon-delete float-end" data-bs-toggle="modal" data-bs-target="#successModal" @click="deleteFileChange('AWS')"></button>
                    <button type="button" class="button_icons btn icon-download float-end" @click="downloadFileChange('AWS')"></button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center align-items-center fw-bold fs-4 mt-2">
              <p>Content not available</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-else class="container-fluid position-absolute top-50 start-50 translate-middle row justify-content-center mt-4" style="height: 90%;" >
      <div class="col-12 cold-sm-12 col-md-12 col-lg-5 col-xl-5 border border-secondary-subtle rounded-4 border-opacity-10" style="width: 90%; background-color: rgb(235, 235, 235)">
        <div class="col-12 text-start mt-2 d-flex position-relative" style="word-break: break-word;">
          <button type="button" class="btn btn-outline-secondary p-0" style="width: 25px; height: 27px;" @click="backList">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="Gray" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
          </button>
          <div class="ms-2" style="flex: 1;">
            {{ current_path }}
          </div>
          <img class="img-fluid" src="../assets/logo_ctn_new_2024_color.png" alt="logoCTNColor">
        </div>
        <div class="files-list border border-white overflow-scroll mt-2">
          <div v-if="files_in_path">
            <table>
              <tr v-for="(element, index_element) in files_in_path" v-bind:key="index_element">
                <td v-if="element.type == 'd'" class="files-icons folder-icon"></td>
                <td v-else class="files-icons file-icon"></td>
                <td v-if="element.type =='d'" v-bind:class="{ 'pointer-class': element.type == 'd' }" @click="navigate(element.name)">{{ element.name }}</td>
                <td v-else @click="selectFile(element.name, 'local')">{{ element.name }}</td>
                <td>{{ element.size }}</td>
                <td v-if="element.type != 'd' && user_view != 'read_permission'" style="min-width: 90px;">
                  <div class="d-grid gap-2 d-md-block justify-content-md-end">
                    <button class="button_icons btn me-md-2 icon-delete float-end" :disabled="user_view == 'read_permission'" type="button"  data-bs-toggle="modal" data-bs-target="#successModal" @click="deleteFileChange('local')"></button>
                    <button class="button_icons btn icon-download float-end" type="button" @click="downloadFileChange('local')"></button>
                    
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
          <button type="button" class="btn btn-light text-primary m-2 border-dark border-opacity-25 rounded mt-2 float-end btn-sm" @click="openFileSelector" :disabled="loading">Upload files</button>
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange">
      </div>
    </div>


    <div class="modal fade" id="successModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">¡Success!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              The deletion was successful
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="uploadModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">¡Success!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="progress">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                :class="{ 'progress-bar-animated': progress < 100 }"
                :style="{width: progress + '%' }" :aria-valuenow="progress" :aria-valuemin="0" :aria-valuemax="100">
                {{ progress }}%
              </div>
            </div>
          </div>
          <!-- <div class="text-center mt-3">
            <p v-if="error" class="text-danger mt-2"> Error: {{ error }}</p>
            <p v-if="success && !error" class="text-success mt-1"> File upload successful!</p>
          </div> -->
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { postData } from "@/services/api.js";
import { postDataAWS } from "@/services/api.js";
import { postDataUpload } from "@/services/api.js";
import { postDataDownload } from "@/services/api.js";
import { postDataDelete } from "@/services/api.js";
import {Modal} from "bootstrap";

export default {
  name: 'Home',
  data(){
    return {
      current_path: '',
      files_in_path: null,
      selec_ubication: null,
      selec_AWS: null,
      expresion_DS: /^\/media\/data\//,
      expresion_Acust:  /^E:\\Media\\Data\\Audios.*$/,
      current_path_AWS: '',
      files_in_path_AWS: null,
      selectedFile: null,
      current_file_name: null,
      admin: false,
      user_view: this.$store.state.user.roles_client,
      user_group: this.$store.state.user.client_group,
      loading: false,
      success: false,
      error: null,
      progress: 0,
    };
  },
  mounted(){
    if(this.user_group == "ClientesCTN"){
      this.selec_ubication = "DS"
      this.getFolders("/media/data/projects", "DS")
    }
  },
  methods:{
    getFolders: function(path, ubication){
        const formData = new FormData()
        
          if(ubication == 'DS' && !this.expresion_DS.test(this.current_path)){
            if(this.user_view != "all_permission"){
              this.current_path = "/media/data/projects"
            }else{
              this.current_path = "/media/data"
              this.current_path_AWS= "backups-ds"
              this.selec_AWS= "backups-ds"
            }
            formData.append("full_path", this.current_path)
          }else if ( ubication == 'Acoustic' && !this.expresion_Acust.test(this.current_path)){
            if(this.user_view != "all_permission"){
              this.current_path = "E:\\Media\\Data\\Audios"
            }else{
              this.current_path = "E:\\Media\\Data\\Audios"
              this.current_path_AWS = "backpus-underwater-noise"
              this.selec_AWS = "backpus-underwater-noise"
            }
            formData.append("full_path", this.current_path)
          }else{
            formData.append("full_path", path)
          }
        formData.append("data", ubication)
        postData("list_data_disc/", formData).then(res => {
          this.files_in_path = res.data.listado
          console.log(res.data.listado)
        }).catch(error => {
          console.log(error)
        });
    },
    getFoldersAWS: function(){
      if (this.user_view == 'all_permission'){
        const formData = new FormData()
        formData.append("bucket", this.current_path_AWS)

        postDataAWS("list_data_buckets/", formData).then(res => {
          this.files_in_path_AWS = res.data.contenido
        }).catch(error => {
          console.log(error)
        });
      }
    },
    navigate: function(new_path){
      if (this.selec_ubication == 'Acoustic'){
        this.current_path = this.current_path+"\\"+new_path
      }else{
        this.current_path = this.current_path+"/"+new_path
      }
      console.log(this.current_path)
      this.getFolders(this.current_path, this.selec_ubication)
    },
    navigateAWS: function(new_path){
      this.current_path_AWS = this.current_path_AWS+"/"+new_path
      this.getFoldersAWS(this.current_path_AWS)
    },
    backList(){
      let separator = ''
      let backOK = false
      if (this.selec_ubication == 'Acoustic' && this.current_path != "E:\\Media\\Data\\Audios"){
        separator = "\\"
        backOK = true
      }else if ( this.selec_ubication == 'DS' && this.current_path != "/media/data"){
        separator = "/"
        backOK = true
      }
       if (backOK){
        let elementos = this.current_path.split(separator)
        elementos.pop()
        this.current_path = elementos.join(separator)
       }
      this.getFolders(this.current_path, this.selec_ubication)
    },
    backListAWS(){
      if (this.current_path_AWS != this.selec_AWS){
        let elementos = this.current_path_AWS.split('/')
        elementos.pop()
        this.current_path_AWS = elementos.join('/')
      }
        this.getFoldersAWS(this.current_path_AWS)
    },
    selectFile(filename, type_data){
      if (this.selec_ubication == 'Acoustic'){
        this.current_file_name = this.current_path+"\\"+filename
      }else{
        this.current_file_name = this.current_path+"/"+filename
      }
      if (type_data =='AWS'){
        this.current_file_name = this.current_path_AWS +"/"+filename
      }
      console.log('Archivo seleccionado:', this.current_file_name)
    },
    openFileSelector(){
      this.$refs.fileInput.click()
    },
    handleFileChange(event){
      this.selectedFile = event.target.files[0]

      this.loading = true
      this.error = null
      this.success = false
      this.progress = 0

      const formData = new FormData()
      formData.append("full_path", this.current_path)
      formData.append("ubicacion", this.selec_ubication)
      formData.append("data", this.selectedFile)

      const modalElement = document.getElementById('uploadModal')
      const modalInstance = new Modal(modalElement)
      modalInstance.show()

      postDataUpload("upload_file_in_server/", formData, progressEvent => {
        if (progressEvent.lengthComputable){
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }else{
          console.warn("Hola")
        }}, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }).then(res => {
          this.success = true
          this.selectedFile = null
        }).catch(error => {
          console.log(error)
          this.error = error.message
        }).finally(() => {
          this.loading = false;
        });
      this.getFolders(this.current_path, this.selec_ubication)
    },
    downloadFileChange(typefile){
      let selectURL = ""
      const formData = new FormData()
      if (typefile == 'local'){
        formData.append("file_path", this.current_file_name)
        formData.append("ubicacion", this.selec_ubication)
        selectURL = "download_file_server/"
      }else{
        formData.append("name_bucket", this.selec_AWS)
        formData.append("data", this.current_file_name)
        selectURL = "download_file_AWS/"
      }
      postDataDownload(selectURL, formData).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = res.headers['content-disposition']
        console.log(a.download)
        document.body.appendChild(a)
        a.click()

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        }).catch(error => {
          console.log(error)
        });

    },
    deleteFileChange(typefile){
      console.log('Archivo seleccionado:', this.current_file_name)
      const formData = new FormData()
      if( typefile == 'local'){
        formData.append("file_path", this.current_file_name)
        formData.append("ubicacion", this.selec_ubication)

        postDataDelete("delete_file_server/", formData).then(res => {
            //OK
          }).catch(error => {
            console.log(error)
          });
        this.getFolders(this.current_path, this.selec_ubication)
      }else{
        formData.append("name_bucket", this.selec_AWS)
        formData.append("data", this.current_file_name)

        console.log(formData)

        postDataDelete("delete_file_AWS/", formData).then(res => {
            //OK
          }).catch(error => {
            console.log(error)
          });
        this.getFoldersAWS(this.current_path_AWS)
      }

    },
    loginAdmin: function(){
      const keycloackRoles = this.$store.state.user.roles
      if (!keycloackRoles.includes('admin-realm')){
        this.logout()
      }else{
        this.admin = true
      }
    },
    logout: function(){
      this.$store
        .dispatch("user/logout")
        .then(() => this.$router.push("/"))
        .catch(err => console.log(err));

    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

table {
  width: 100%;
}

.local-files-container{
  background-color: rgb(235, 235, 235);
  height: 100%;
  width: 47%;
  
}

.files-list{
  height: 84%;
  font-size: 0.9rem;
  background-color: white;
  margin-left: -10px;
  padding: 8px;
  transform: translateX(5px);
}

tr:hover{
 background-color: rgba(223, 229, 231, 0.89);
}

td{
  text-align: left;
  padding: 2px 10px;
}

.dropdown-item{
  color: black;
}

.dropdown-item:hover{
  background-color: rgba(223, 229, 231, 0.89);
}

.files-icons{
  height: 13px;
  width: 13px;
  background-repeat: no-repeat;
  background-position: center;
}

.button_icons{
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  background-position: center;
}

.btn-light:hover{
  background-color: #f0eeee;
}

.file-icon{
  background-image: url('~@/assets/icons/icon_file.svg');
}

.folder-icon{
  background-image: url('~@/assets/icons/icon_folder 1.svg');
}

.icon-delete{
  background-image: url('~@/assets/icons/icon_delete.svg');
}

.icon-download{
  background-image: url('~@/assets/icons/icon_download.svg');
}

.icon-delete:hover{
  background-image: url('~@/assets/icons/icon_delete_hover.svg');
}

.icon-download:hover{
  background-image: url('~@/assets/icons/icon_download_hover.svg');
}

.img-fluid{
  width: 50px;
  height: 18px;
  margin-top: 4px;
}

.img-fluid-AWS{
  width: 50px;
  height: 31px;
}

.pointer-class{
  cursor: pointer;
}

.test-button{
  padding: 8px;
  background-color: #ddd;
  width: 100px;
  cursor: pointer;
}

.modal{
  z-index: 10000;
}

.scale-image{
  width: 80px;
}

.title{
  color: white; 
}

.selector-container{
  background: rgb(1, 87, 116); 
  width: 400px; 
  height: 30px; 
  color: white;
  margin-left: 200px;
}

.form-select-style{
  padding: 0px; 
  height: 20px; 
  font-size: 0.8rem;
}

.text-ubicacion{
  font-size: 0.9rem; 
  margin-top: 2px;
}

.container-degradado{
  background: rgb(0,59,79);
  background: linear-gradient(90deg, rgba(0,59,79,1) 0%, rgba(0,167,225,1) 100%);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%;
  right: 0;
  border-radius: 8px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

</style>
